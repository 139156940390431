import SlideInContainer from "components/common/SlideInContainer";
import PageContent from "components/page/PageContent";
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";
import { css, cx } from "linaria";
import React from "react";

const sectionContainer = css`
  display: flex;
  justify-content: center;
  padding: 4rem 10px;
  overflow: hidden;
`;

const sectionInner = css`
  display: flex;
  width: 100%;
  @media (max-width: 1100px) {
    flex-direction: column;
    gap: 40px;
  }
`;

const sectionLeft = css`
  display: flex;
  flex-grow: 1;
  width: 60%;
  @media (max-width: 1100px) {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
`;

const sectionRight = cx(
  sectionLeft,
  css`
    width: 40%;
    justify-content: flex-end;
    @media (max-width: 1100px) {
      justify-content: center;
      width: 100%;
    }
  `
);

const fancyTitle = css`
  background-image: linear-gradient(to left, var(--teal-500), var(--blue-500));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 900;
  font-size: 32px;
  line-height: 2;
`;

const textWrapper = css`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  h3 {
    font-weight: 900;
    font-size: 18px;
  }
  p {
    max-width: 693px;
  }
`;

const learnList = css`
  display: flex;
  flex-direction: column;
  padding: 0;
  list-style: none;
  gap: 20px;
`;

const learnRow = css`
  display: flex;
`;

const iconStyles = css`
  width: 25px;
  height: 25px;
  margin: 5px 25px 0px 0px;
  aspect-ratio: 1;
  overflow: visible;
`;

const speakersList = cx(
  learnList,
  css`
    gap: 40px;
  `
);

const speakerRow = css`
  display: flex;
  gap: 20px;
  align-items: center;
`;

const avatarWrapper = css`
  width: 95px;
  aspect-ratio: 1;
  border-radius: 50%;
  overflow: hidden;
`;

const avatar = css`
  width: 95px !important;
  height: 95px !important;
  aspect-ratio: 1 !important;
`;

const imageWrapper = css`
  height: 100%;
  flex-grow: 1;
  display: flex;
  align-items: flex-end;

  @media (max-width: 1100px) {
    justify-content: center;
  }

  @media (max-width: 600px) {
    display: block;
    width: 100%;
  }
`;

const imageStyles = css`
  width: 553px !important;
  height: 486px !important;
  margin-bottom: -7rem !important;
  @media (max-width: 600px) {
    width: 100% !important;
    height: auto !important;
  }
`;

const AboutSection = ({ data }) => {
  const renderRightSection = () => {
    if (data.speakers !== null) {
      return (
        <div className={textWrapper}>
          <ul className={speakersList}>
            <SlideInContainer>
              <h3>Featured Speakers</h3>
            </SlideInContainer>
            {data.speakers.map(speaker => (
              <li className={speakerRow}>
                <div className={avatarWrapper}>
                  <GatsbyImage
                    image={getImage(speaker.image)}
                    alt={speaker.name}
                    className={avatar}
                    objectFit="contain"
                  />
                </div>
                <div>
                  <h4>{speaker.name}</h4>
                  <p>{speaker.title}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      );
    } else
      return (
        <div className={imageWrapper}>
          <GatsbyImage
            image={getImage(data.image)}
            alt={data.title}
            loading="eager"
            className={imageStyles}
            objectFit="contain"
          />
        </div>
      );
  };
  return (
    <section className={sectionContainer}>
      <PageContent>
        <div className={sectionInner}>
          <div className={sectionLeft}>
            <div className={textWrapper}>
              <div>
                <SlideInContainer>
                  <h2 className={fancyTitle}>{data.title}</h2>
                </SlideInContainer>
                <p>{data.description}</p>
              </div>
              <ul className={learnList}>
                {data.learnTitle ? (
                  <SlideInContainer>
                    <h3>{data.learnTitle}</h3>
                  </SlideInContainer>
                ) : (
                  <></>
                )}
                {data.learnLines.map(line => (
                  <SlideInContainer>
                    <li className={learnRow}>
                      <StaticImage
                        src="../img/check.png"
                        alt="checked"
                        className={iconStyles}
                        placeholder="blurred"
                        objectFit="contain"
                      />
                      <p>{line}</p>
                    </li>
                  </SlideInContainer>
                ))}
              </ul>
              {data.promoText}
            </div>
          </div>
          <div className={sectionRight}>{renderRightSection()}</div>
        </div>
      </PageContent>
    </section>
  );
};

export default AboutSection;
