import Features from "components/common/Features";
import InfoSlider from "components/common/InfoSlider";
import IndustryLeadersCards from "components/common/sliders/IndustryLeadersCards";
import TrustedPartnerSection from "components/common/TrustedPartnerSection";
import GlobalLayout from "components/page/GlobalLayout";
import AboutSection from "components/webinar/updated/AboutSection";
import WebinarHeader from "components/webinar/updated/WebinarHeader";
import { graphql, StaticQuery } from "gatsby";
import React from "react";
import Helmet from "react-helmet";

export default function HydroDemo() {
  const renderContent = data => {
    const renderPromoText = () => {
      return (
        <div>
          <p>Register today and get a free Hydro Flask!*</p>
          <p style={{ fontSize: "12px" }}>*Terms &amp; Conditions apply</p>
        </div>
      );
    };
    const pageData = {
      header: {
        preTitle: "Live Demo",
        title: "Next-Generation Cloud Video Security",
        formId: "06202e1a-f3b9-4976-9c3d-185da90049a5",
        formTitle: "Request Demo",
        image: data.headerImage,
      },

      aboutSection: {
        title: "About the Rhombus Demo",
        description:
          "See why Rhombus is the leading physical security solution for organizations of all sizes. During your demo, you’ll learn:",
        learnLines: [
          "Why a cloud-native solution is superior to on-premise video security systems",
          "The benefits of using a unified platform with smart cameras, sensors, and integrations",
          "How to improve safety and productivity with powerful AI analytics",
        ],
        speakers: null,
        image: data.learnImage,
        promoText: renderPromoText(),
      },
      benefits: {
        list: [
          {
            title: "Enjoy an all-in-one solution that grows with your needs.",
            tabTitle: "Infinite Scalability",

            description:
              "With a broad suite of products that is constantly growing, Rhombus provides a true single-pane-of-glass experience. Remotely manage access control, security cameras, alarms, sensors, and integrations from a centralized platform to improve visibility and operations at scale.",
            image: data.benefit1,
          },
          {
            tabTitle: "Seamless Deployment",
            title: "Register plug-and-play devices in as little as 5 minutes.",
            description:
              "Complex NVR/DVRs are a thing of the past. Rhombus’ modern architecture ensures fast and easy deployment from anywhere in the world. With simplified hardware infrastructure, installation is seamless with a single cable connection via PoE, eliminating the need for manual updates. ",
            image: data.benefit2,
          },
          {
            tabTitle: "Proactive Alerts",
            title:
              "Stay in the know with real-time alerts for faces, vehicles, and more.",
            description:
              "Save time, accelerate investigations, and receive real-time intelligent alerts with Rhombus AI Analytics. Through machine-learning, the system automatically learns what ‘normal’ looks like in your spaces and becomes smarter and more effective.",
            image: data.benefit4,
          },
          {
            tabTitle: "Easy Investigations",
            title:
              "Save time with multi-camera investigations and smart search.",
            description:
              "No more manual scrubbing. Camera and sensor data are layered together for fast investigations. Quickly search past footage, search for events, and securely share clips via email, text, or URL all from the Rhombus timeline.",
            image: data.benefit3,
          },
        ],
      },
      features: [
        {
          icon: data.icon1,
          iconAlt: "handshake",
          title: "Trusted Partner",
          p:
            "Our mission is to build a true partnership and provide simple, smart, and powerful solutions that fit your unique needs.",
        },
        {
          icon: data.icon2,
          iconAlt: "check mark",
          title: "Industry Veterans",
          p:
            "Since pioneering cloud security cameras, we’ve continued to create new, innovative ways to serve our customers. ",
        },
        {
          icon: data.icon3,
          iconAlt: "security",
          title: "Secure by Default",
          p:
            "Rhombus’ track record speaks for itself with zero-trust security framework and zero breaches.",
        },
        {
          icon: data.icon4,
          iconAlt: "support",
          title: "World-Class Support",
          p:
            "We guarantee you’ll receive a phenomenal experience from a team dedicated to your success.",
        },
      ],
      customerStories: {
        title: "Industry Leaders Trust Rhombus as their Security Solution",
        cards: [
          {
            img: data.story1,
            logo: {
              img: data.cslogo1,
              width: "131px",
              alt: "University Area CDC",
            },
            quote:
              "“I feel like the Rhombus team genuinely cares about our organization and the problems we're trying to solve—they were never just trying to sell us cameras.”",
          },
          {
            img: data.story2,
            logo: {
              img: data.cslogo2,
              width: "143px",
              alt: "Blake's Lota Burger",
            },
            quote:
              "“Similar tech on the surface, but Rhombus had more features, the portal was more user-friendly, and it had everything we needed. That was the start of our wonderful partnership.”",
          },
          {
            img: data.story3,
            logo: {
              img: data.cslogo3,
              width: "217px",
              alt: "Highlands School District",
            },
            quote:
              "“My biggest priority was not the latest discount another company could give me, but that my Superintendent will say that I chose the right partner and security platform in five years. That’s why I chose Rhombus over Verkada.”",
          },
        ],
      },
    };
    return (
      <GlobalLayout color="var(--gradient-light)" landingPage>
        <Helmet>
          <title>
            Rhombus - Enterprise Video Security & Surveillance Webinars
          </title>
          <meta
            name="description"
            content="No DVR/NVRs required. Learn about modern cloud security cameras and environmental sensors in the next live webinar."
          />
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <WebinarHeader data={pageData.header} />
        <AboutSection data={pageData.aboutSection} />
        <InfoSlider
          data={pageData.benefits.list}
          title="The Benefits of Rhombus"
          color="var(--nuetral-100)"
        />
        <TrustedPartnerSection button={false} />
        <Features
          data={pageData.features}
          title="Rhombus Platform Features"
          color="var(--nuetral-100)"
        />
        <IndustryLeadersCards />
      </GlobalLayout>
    );
  };
  const query = graphql`
    query {
      headerImage: file(
        relativePath: {
          eq: "components/webinar/updated/img/webinar-flask-text-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      learnImage: file(
        relativePath: {
          eq: "components/webinar/updated/img/learn-image-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      benefit1: file(
        relativePath: { eq: "components/demo/img/infinite-scalability-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      benefit2: file(
        relativePath: { eq: "components/demo/img/seamless-deployment-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      benefit3: file(
        relativePath: { eq: "components/demo/img/proactive-alerts-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      benefit4: file(
        relativePath: { eq: "components/demo/img/easy-investigations-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon1: file(
        relativePath: { eq: "components/industries/img/icons/trust.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon2: file(
        relativePath: { eq: "components/industries/img/icons/system.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon3: file(
        relativePath: { eq: "components/demo/img/secure-by-default.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon4: file(relativePath: { eq: "components/demo/img/headset.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      competitorLogo: file(
        relativePath: { eq: "components/vs/updated/img/Verkada.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      cslogo1: file(
        relativePath: { eq: "components/common/logos/uacdc-white-logo.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      cslogo2: file(
        relativePath: { eq: "components/common/logos/blakes-lotaburger.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      cslogo3: file(
        relativePath: {
          eq: "components/common/logos/highlands-school-district.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      story1: file(
        relativePath: { eq: "components/vs/updated/img/story-1-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      story2: file(
        relativePath: { eq: "components/vs/updated/img/story-2-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      story3: file(
        relativePath: { eq: "components/vs/updated/img/story-3.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
    }
  `;

  return <StaticQuery query={query} render={renderContent} />;
}
